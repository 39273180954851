import {getZendeskClient} from '../resources/zendesk';

export type ZendeskRequestConfig = {
    url: string
    type: 'GET' | 'POST'
    secure: boolean
    headers: { 'x-api-key': string, 'accept'?: 'application/json' }
    data?: any
    contentType?: 'application/json'
}

export class HttpClient {
    secureConnection: boolean;
    accessToken: string;

    constructor() {
        this.secureConnection = this.getSecureConfig().secure;
        this.accessToken = this.getSecureConfig().token;
    }

    get<T>(url: string): Promise<T> {
        const config: ZendeskRequestConfig = {
            url: url,
            headers: {'x-api-key': this.accessToken},
            type: 'GET',
            secure: this.secureConnection
        };
        return this.execute(config);
    }

    post<T>(url: string, data: string): Promise<T> {
        const config: ZendeskRequestConfig = {
            url: url,
            headers: {
                'x-api-key': this.accessToken,
                'accept': 'application/json'
            },
            type: 'POST',
            secure: this.secureConnection,
            data: data,
            contentType: 'application/json'
        };

        return getZendeskClient().request(config).then((response: any) => {
            return response;
        });
    }

    execute(config: ZendeskRequestConfig) {
        return getZendeskClient().request(config).then((response: any) => {
            return response.data;
        });
    }

    getSecureConfig() {
        if (process.env.NODE_ENV !== 'development')
            return {'token': '{{setting.api_token}}', 'secure': true};
        return {'token': process.env.BFF_DEV_ACCESS_TOKEN, 'secure': false};
    }

}