import { AssignedLicense } from '../../models/Assignment.model';
import React, { useEffect, useState } from 'react';
import { Body, Cell, Head, HeaderCell, HeaderRow, Table, Row } from '@zendeskgarden/react-tables';
import { getAssignments } from '../../services/assignments.service';
import queryString from 'query-string';
import { MD } from '@zendeskgarden/react-typography';
import { Spinner } from '@zendeskgarden/react-loaders';
import { Chrome } from '@zendeskgarden/react-chrome';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Grid } from '@zendeskgarden/react-grid';

function getEmployeeNumber(param: string): string {
    const params = queryString.parse(param);
    return params['employeeNumber'].toString();
}

function getEmail(param: string): string {
    const params = queryString.parse(param);
    return params['employeeEmail'].toString();
}

function sortLicenseTitleByVendor(licenses: AssignedLicense[]) {
    licenses.sort((license: AssignedLicense, otherLicense: AssignedLicense) => (license.manufacturer.name > otherLicense.manufacturer.name) ? 1 : -1);
    const titlesWithoutVendorName = licenses.filter((license) => license.manufacturer.name == '').length;
    return licenses.slice(titlesWithoutVendorName).concat(licenses.slice(0, titlesWithoutVendorName));
}

function getFormattedDate(date: string) {
    return new Date(date).toLocaleDateString('en-US', dateOptions as any);
}

const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
export default function AssignedLicenses() {

    const [assignedLicenses, setAssignedLicenses] = useState<AssignedLicense[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const employeeNumber = getEmployeeNumber(window.location.search);
    const employeeEmail = getEmail(window.location.search);
    const [isNotValidTW, setIsNotValidTW] = useState(false);
    const [isLicenseAssigned, setIsLicenseAssigned] = useState(true);

    useEffect(() => {
        getAssignments(employeeNumber).then(data => {
            const licenses = sortLicenseTitleByVendor(data);
            if (licenses.length < 1) {
                setIsLicenseAssigned(false);
            }
            setAssignedLicenses(licenses);
            setIsLoading(false);
            setIsNotValidTW(false);
        }).catch(err => {
            console.error(err);
            setIsNotValidTW(true);
        });
    }, []);

    return (
        <>
            <ThemeProvider>
                <Chrome>
                    <Grid gutters={false}>
                        <h3 style={{ marginTop: -2 }}>Assigned Licenses</h3>
                        {!isLicenseAssigned && <MD data-testid='no-license-assigned-message'>No license assigned for the requester {employeeEmail}</MD>}
                        {!isLoading && isLicenseAssigned && <div style={{
                            overflowX: 'scroll',
                            minHeight: '30%',
                            maxHeight: '90%',
                            overflowY: 'scroll'
                        }}><Table width={'800px'}>
                                <Head>
                                    <HeaderRow>
                                        <HeaderCell>Name</HeaderCell>
                                        <HeaderCell>Vendor</HeaderCell>
                                        <HeaderCell width="120px">Assigned date</HeaderCell>
                                    </HeaderRow>
                                </Head>
                                <Body data-testid='assignedLicenseTable'>
                                    {assignedLicenses.map((assignment, key) => {
                                        return (<Row key={key}>
                                            <Cell ><MD>{assignment.name}</MD></Cell>
                                            <Cell><MD>{assignment.manufacturer.name}</MD></Cell>
                                            <Cell width="120px" ><MD>{getFormattedDate(assignment.created)}</MD></Cell>
                                        </Row>);
                                    })}
                                </Body>

                            </Table></div>}
                        {isLoading && !isNotValidTW && <Spinner data-testid='loader' size={'50'} />}
                        {isNotValidTW && <MD data-testid='error-message-for-assigned-license'>The app is not able to display the list of software titles assigned to
                            the Thoughtworker due to technical reasons. Please drop an email at servicenow-support@thoughtworks.com</MD>}
                    </Grid>
                </Chrome>
            </ThemeProvider>
        </>);
}