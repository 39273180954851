import { HttpClient } from '../helper/HttpClient';
import { AssignedLicense } from '../models/Assignment.model';

function constructUrl(path: string) {
    return `${process.env.REACT_APP_BFF_BASE_URL}/software-license-assignment/softwares` + path;
}

export function getAssignments(employeeNumber: string): Promise<AssignedLicense[]> {
    const path = '/assignments?employeeNumber=' + employeeNumber;
    return new HttpClient().get(constructUrl(path));
}

export function postAssignments(titleId: string, employeeNumber: string, ticketId: string): Promise<AssignedLicense[]> {

    const data = JSON.stringify({
        'employeeNumber': employeeNumber,
        'softwareTitleId': titleId,
        'ticketId': ticketId.toString()
    });

    return new HttpClient().post(constructUrl('/requests'), data);
}