/* istanbul ignore file */
/*
Refer Zendesk Client and Core API:
https://developer.zendesk.com/api-reference/apps/apps-core-api/client_api/
https://developer.zendesk.com/api-reference/apps/apps-core-api/core_api/
https://developer.zendesk.com/api-reference/apps/apps-support-api/all_locations/ => Common Object Specs
https://developer.zendesk.com/api-reference/apps/apps-support-api/ticket_sidebar/ => Ticket Sidebar Object Specs
 */

// @ts-ignore
import ZendeskClient, {BaseData, DefaultCallBack, RootData, User} from '../zaf';


export function getCurrentUser(): Promise<User> {
    const resourceId = 'currentUser';
    return getByResourceId<User>(resourceId);
}

export function getTicketRequester(): Promise<User> {
    const resourceId = 'ticket.requester';
    return getByResourceId<User>(resourceId);
}

export function getTicketCollaborators(): Promise<any> {
    const resourceId = 'ticket.collaborators';
    return getByResourceId<any>(resourceId);
}

export function getTicketId(): Promise<string> {
    const resourceId = 'ticket.id';
    return getByResourceId<string>(resourceId);
}

export function onAppRegistered(callback: DefaultCallBack) {
    getZendeskClient().on('app.registered', callback);
}

export function getByResourceId<E extends BaseData>(resourceId: string): Promise<E> {
    return new Promise((resolve, reject) => {
        getZendeskClient().get<E>(resourceId)
            .then(
                function (clientData: RootData<E>) {
                    resolve(clientData[resourceId]);
                })
            .catch(reject);
    });

}

export function getZendeskClient(): ZendeskClient {
    return (window as never)['ZendeskClient'] as ZendeskClient;
}